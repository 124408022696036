import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { GatsbyImage } from 'gatsby-plugin-image'
import AspectRatioContainer from 'components/aspect-ratio-container'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import AdditionalResources from 'components/new/additional-resources'
// import ImageGallery from 'react-image-gallery'
// import ImageGalleryStyle from 'components/image-gallery/style.js'

// const rsvpLink = 'https://forms.gle/qL6rgvx2fcAu95JD7'

const schedule = {
  tuesday: [
    {
      time: '8:30 AM',
      title: 'Show Opens to Attendees',
    },
    {
      time: '9:00 - 11:00 AM',
      title: 'Precision Ag Certification',
      speaker: 'Hutson, Inc. Precision Ag Team',
      location: 'Booth E1014-1316',
    },
    {
      time: '9:00 - 9:45 AM',
      title: 'Boosting Soil Health witih Cover Crops',
      speaker: 'Kristin Poley',
      location: 'Speaker Tent',
    },
    {
      time: '9:30 AM',
      title: 'Tri-County Electric Safety Demo',
      location: 'Booth E712',
    },
    {
      time: '10:00 - 10:45 AM',
      title: 'Benefits of Amplify D Emergence Aid, Wex Wetting Agent and Syntose Liquid Sugar',
      speaker: 'Greg Wetzel',
      location: 'Speaker Tent',
    },
    {
      time: '11:00 - 11:45 AM',
      title: 'Hot Legal Topics in Ag for 2023',
      speaker: 'Mike Zahrt',
      location: 'Speaker Tent',
    },
    {
      time: '11:00 AM - 12:30 PM',
      title: 'Sprayer Demonstrations',
      location: 'Demo Plot',
      link: 'https://www.theagroexpo.com/schedule/demonstrations',
    },
    {
      time: '11:30 AM',
      title: 'Tri-County Electric Safety Demo',
      location: 'Booth E712',
    },
    {
      time: '12:00 - 1:00 PM',
      title: 'Disappearing Insects: The Consequences of Global Insect Decline',
      speaker: 'Dr. Chris Difonzo',
      location: 'Speaker Tent',
    },
    {
      time: '1:15 - 2:00 PM',
      title: 'Wind and Solar Contracts: The Good, The Bad and The Ugly',
      speaker: 'Mike Fraleigh',
      location: 'Speaker Tent',
    },
    {
      time: '1:30 PM',
      title: 'Tri-County Electric Safety Demo',
      location: 'Booth E712',
    },
    {
      time: '2:00 - 3:30 PM',
      title: 'Sprayer Demonstrations',
      location: 'Demo Plot',
      link: 'https://www.theagroexpo.com/schedule/demonstrations',
    },
    {
      time: '2:15 - 3:00 PM',
      title: 'Increasing the Value of your Forage Crop with Triticale',
      speaker: 'Kim Cassida',
      location: 'Speaker Tent',
    },
    {
      time: '4:00 PM',
      title: 'Show Closes',
    },
  ],
  wednesday: [
    {
      time: '8:30 AM',
      title: 'Show Opens to Attendees',
    },
    {
      time: '9:00 AM',
      title: 'FFA Tractor Driving Contest Begins',
      location: 'FFA Tractor Driving Contest Area',
    },
    {
      time: '9:00 AM',
      title: 'FFA Crop Management Challenge Educational Booths Open',
      location: 'FFA CMC Plots',
      link: 'https://www.theagroexpo.com/ffa',
    },
    {
      time: '9:00 - 11:00 AM',
      title: 'Precision Ag Certification',
      speaker: 'Hutson, Inc. Precision Ag Team',
      location: 'Booth E1014-1316',
    },
    {
      time: '9:00 - 9:45 AM',
      title: 'Looking to the Future: Sustainability at Michigan Sugar Company',
      speaker: 'Corey Guza, Ph.D.',
      location: 'Speaker Tent',
    },
    {
      time: '9:30 AM',
      title: 'Tri-County Electric Safety Demo',
      location: 'Booth E712',
    },
    {
      time: '10:00 - 10:45 AM',
      title:
        "One Farmer's Trash is Another Farmer's Profitability: Managing Corn Residue for your Bottom Line",
      speaker: 'Stephanie Smith',
      location: 'Speaker Tent',
    },
    {
      time: '11:00 AM - 12:30 PM',
      title: 'Sprayer Demonstrations',
      location: 'Demo Plot',
      link: 'https://www.theagroexpo.com/schedule/demonstrations',
    },
    {
      time: '11:30 AM',
      title: 'Tri-County Electric Safety Demo',
      location: 'Booth E712',
    },
    {
      time: '1:00 PM',
      title: 'FFA Crop Management Challenge Educational Booths Close',
      location: 'FFA CMC Plots',
      link: 'https://www.theagroexpo.com/ffa',
    },
    {
      time: '1:15 - 2:00 PM',
      title: 'Wind and Solar Contracts: The Good, The Bad and The Ugly',
      speaker: 'Mike Fraleigh',
      location: 'Speaker Tent',
    },
    {
      time: '1:30 PM',
      title: 'Tri-County Electric Safety Demo',
      location: 'Booth E712',
    },
    {
      time: '2:00 - 3:00 PM',
      title: 'Sprayer Demonstrations',
      location: 'Demo Plot',
      link: 'https://www.theagroexpo.com/schedule/demonstrations',
    },
    {
      time: '3:00 PM',
      title: 'Show Closes',
    },
  ],
}

const hotels = [
  {
    title: 'Fairfield Inn & Suites by Marriott',
    distance: '27 mi',
    address: '3320 Preyde Blv, Lansing, MI 48912',
    rate: '$109/night + Tax',
    breakfast: null,
    link: null,
    phone: '517-874-6500',
    group: '2024 AgroExpo',
    code: 'AGR',
    cutOffDate: '7/22/2024',
  },
  {
    title: 'Hyatt Place',
    distance: '27 mi',
    address: '2401 Showtime Dr, Lansing, MI 48912',
    rate: '$121/night (King) + Tax',
    breakfast: 'Includes breakfast',
    link: 'https://www.hyatt.com/en-US/group-booking/LANZL/G-AGXP',
    phone: '888-492-8847',
    group: 'AgroExpo',
    code: 'G-AGXP',
    cutOffDate: '7/28/2024',
  },
  {
    title: 'Courtyard by Marriott',
    distance: '27 mi',
    address: '2710 Lake Lansing Rd, Lansing, MI 48912',
    rate: '$109/night + Tax',
    breakfast: null,
    link: null,
    phone: '517-482-0500',
    group: 'AgroExpo',
    code: 'AGR',
    cutOffDate: '7/22/2024',
  },
  {
    title: 'Holiday Inn Express',
    distance: '31 mi',
    address: '9490 Woodlane Dr, Dimondale, MI 48821',
    rate: '$114/night (King) + Tax',
    breakfast: null,
    link: 'https://www.hiexpress.com/redirect?path=hd&brandCode=EX&localeCode=en&regionCode=1&hotelCode=LANSW&_PMID=99801505&GPC=AGE&cn=no&viewfullsite=true',
    phone: '517-646-7000',
    group: 'AgroExpo',
    code: 'AGROEXPO',
    cutOffDate: '7/20/2024',
  },
  {
    title: 'Crowne Plaza',
    distance: '36 mi',
    address: '925 S Creyts Rd, Lansing, MI 48917',
    rate: '$129/night (King) + Tax',
    breakfast: 'Includes breakfast',
    link: 'https://www.ihg.com/crowneplaza/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qIta=99801505&icdv=99801505&qSlH=LANCP&qCiD=12&qCiMy=072024&qCoD=15&qCoMy=072024&qGrpCd=AGR&qAAR=6CBARC&qRtP=6CBARC&setPMCookies=true&qSHBrC=CP&qDest=925%20South%20Creyts%20Road,%20Lansing,%20MI,%20US&srb_u=1&qChAge=&qRmFltr=target=%22_blank%22%22',
    phone: '517-391-1317',
    group: '2024 AgroExpo',
    code: 'AGR',
    cutOffDate: '7/26/2024',
  },
]

const AgroExpo2024Page = props => {
  const {
    data: { agStormImage, heroImage, kondexImage, logo, newEquipmentImage, partsImage, venueImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>AgroExpo 2024 | Hutson Inc</title>
        {/* <ImageGalleryStyle /> */}
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='AgroExpo 2024'
        overlayOpacity={0.5}
      >
        <Subtitle>Innovation in agriculture</Subtitle>
        <EventInfo>August 13-14, 2024 | St. Johns, MI</EventInfo>
        <Spacer size='s' />
        <Button as={ButtonA} href='https://www.theagroexpo.com' color='yellow'>
          Learn more at TheAgroExpo.com
        </Button>
        <Spacer size='l' className='mobile-hidden' />
      </Hero>

      <Content kind='full'>
        <Grid>
          <AboutColumn>
            <H2>What is AgroExpo?</H2>
            <P>
              Discover quality information and practical demonstrations tailored to benefit growers
              at the 2024 AgroExpo. This year's theme focuses on Choppers and Strip-Tilling. Mark
              your calendars for mid-July when the official show guide will be released, including
              the schedule of events and details on demonstrations.
            </P>
            <P>
              The event is Tuesday, August 13th 8:30 AM - 4:00 PM and Wednesday, August 14th 8:30 AM
              - 3:00 PM at 5605 N Findlay Rd, St. Johns, MI 48879.
            </P>
            <Spacer size='s' />
            <Button as={ButtonA} href='https://www.theagroexpo.com' color='yellow'>
              Learn more at TheAgroExpo.com
            </Button>
          </AboutColumn>
          <LogoColumn>
            <GatsbyImage image={logo.childImageSharp.gatsbyImageData} alt='AgroExpo 2024' />
          </LogoColumn>
        </Grid>
        <Spacer />
        <H2>What to expect from us</H2>
        <P>
          We're bringing the latest and greatest equipment along with our team of experts to show
          you all the bells and whistles.
        </P>
        <Spacer size='m' />
        <Grid>
          <OneHalfColumn>
            <SessionContainer ratio='50'>
              <SessionImageContainer>
                <SessionImage image={newEquipmentImage.childImageSharp.gatsbyImageData} alt='' />
              </SessionImageContainer>
            </SessionContainer>
            <SessionContent>
              <H3>New Equipment</H3>
              <P>
                If you were at last year's AgroExpo, you know that Hutson doesn't skip on the
                equipment. We'll have everything from mowers to forage harvesters. Our team of
                experts will be available to answer any questions you may have.
              </P>
            </SessionContent>
          </OneHalfColumn>
          <OneHalfColumn>
            <SessionContainer ratio='50'>
              <SessionImageContainer>
                <SessionImage image={partsImage.childImageSharp.gatsbyImageData} alt='' />
              </SessionImageContainer>
            </SessionContainer>
            <SessionContent>
              <H3>Exclusive Parts Discounts</H3>
              <P>
                Get ready for an unbeatable deal at this year's AgroExpo! We're thrilled to announce
                an exclusive 10% discount on qualifying parts purchases made during the event.
                Whether you're looking to upgrade your machinery or stock up on essential parts,
                AgroExpo is the time to save big. Visit our tent at the AgroExpo to place an order
                and save!
              </P>
            </SessionContent>
          </OneHalfColumn>
          <OneHalfColumn>
            <SessionContainer ratio='50'>
              <SessionImageContainer>
                <SessionImage image={kondexImage.childImageSharp.gatsbyImageData} alt='' />
              </SessionImageContainer>
            </SessionContainer>
            <SessionContent>
              <H3>Kondex Concaves</H3>
              <P>
                Kondex Concaves are revolutionizing the agricultural industry with their innovative
                design and superior performance. Their KX7 and Revolution™ concaves offer farmers a
                game-changing solution that maximizes harvest efficiency and grain quality. Learn
                more at the AgroExpo!
              </P>
            </SessionContent>
          </OneHalfColumn>
          <OneHalfColumn>
            <SessionContainer ratio='50'>
              <SessionImageContainer>
                <SessionImage image={agStormImage.childImageSharp.gatsbyImageData} alt='' />
              </SessionImageContainer>
            </SessionContainer>
            <SessionContent>
              <H3>AgStorm</H3>
              <P>
                Ag Storm delivers cutting-edge equipment designed to enhance farm safety and
                productivity. Their innovative product line, including the Hydra Box™, Field Seal™
                Tool Boxes, Power Box™, and UTV Rock Armor & Rock Hawk, offers farmers a
                comprehensive solution for on-board rock handling systems and more. Learn more at
                the AgroExpo!
              </P>
            </SessionContent>
          </OneHalfColumn>
        </Grid>
        <Spacer />
        {/* </Content>

      <LightSection>
        <Content kind='full'>
          <H2>Schedule</H2>
          <Spacer size='m' />
          <Schedules>
            <div>
              <ScheduleGroupHeader>
                <H3>Tuesday, August 15th</H3>
                <P>8:30 AM - 4:00 PM</P>
              </ScheduleGroupHeader>
              <ScheduleGroupContent>
                <ScheduleTable>
                  {schedule.tuesday.map((obj, key) => (
                    <tr key={key}>
                      <td>
                        <P>
                          <strong>{obj.time}</strong>
                        </P>
                      </td>
                      <td>
                        <P>
                          {obj.title}
                          {obj.speaker || obj.location
                            ? ` | ${[obj.speaker, obj.location].filter(val => val).join(' - ')}`
                            : ''}
                        </P>
                        {obj.link ? (
                          <P>
                            <OutboundLink href={obj.link}>Learn more</OutboundLink>
                          </P>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </ScheduleTable>
              </ScheduleGroupContent>
            </div>
            <div>
              <ScheduleGroupHeader>
                <H3>Wednesday, August 16th</H3>
                <P>8:30 AM - 3:00 PM</P>
              </ScheduleGroupHeader>
              <ScheduleGroupContent>
                <ScheduleTable>
                  <tbody>
                    {schedule.wednesday.map((obj, key) => (
                      <tr key={key}>
                        <td>
                          <P>
                            <strong>{obj.time}</strong>
                          </P>
                        </td>
                        <td>
                          <P>
                            {obj.title}
                            {obj.speaker || obj.location
                              ? ` | ${[obj.speaker, obj.location].filter(val => val).join(' - ')}`
                              : ''}
                          </P>
                          {obj.link ? (
                            <P>
                              <OutboundLink href={obj.link}>Learn more</OutboundLink>
                            </P>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </ScheduleTable>
              </ScheduleGroupContent>
            </div>
          </Schedules>
        </Content>
      </LightSection>

      <Content kind='full'> */}
        <H2>Venue &amp; Hotel Accommodations</H2>
        <Spacer size='m' />
        <VenueGrid>
          <VenueColumn>
            <GatsbyImage
              image={venueImage.childImageSharp.gatsbyImageData}
              alt='North Central Research Station Grounds'
            />
          </VenueColumn>
          <VenueColumn>
            <H3>North Central Research Station Grounds</H3>
            <P>
              <OutboundLink
                href='https://goo.gl/maps/fR5zod2fQpz1LPBN7'
                target='_blank'
                rel='noopener noreferrer'
              >
                5605 N Findlay Rd, St. Johns, MI 48879
              </OutboundLink>
            </P>
            <P>
              AgroExpo is located on the grounds of the North Central Research Station. This 50 acre
              parcel provides a quality infrastructure allowing for safety, convenience, and
              accessibility to over 150 vendors and numerous demonstrations and speakers.
            </P>
            <Spacer size='s' />
            <Button as={ButtonA} href='https://goo.gl/maps/fR5zod2fQpz1LPBN7' color='green' ghost>
              Get directions
            </Button>
          </VenueColumn>
        </VenueGrid>
        <Spacer size='l' />
        <Grid>
          {hotels.map(obj => (
            <HotelColumn key={obj.title} style={{ backgroundColor: '#eaebeb', padding: '24px' }}>
              <H3>{obj.title}</H3>
              <P>{obj.address}</P>
              <P>
                {obj.rate}
                {obj.breakfast ? ` (${obj.breakfast})` : null}
              </P>
              <P>
                Call <OutboundLink href={`tel:${obj.phone}`}>{obj.phone}</OutboundLink> and ask
                about the AgroExpo group rate (code {obj.code}) or click the link below to book
                online.
              </P>
              <Spacer size='s' />
              {obj.link ? (
                <Button as={ButtonA} href={obj.link} color='green' ghost>
                  Book a room
                </Button>
              ) : null}
              <P>
                <small>Group rate cut-off date: {obj.cutOffDate}</small>
              </P>
            </HotelColumn>
          ))}
        </Grid>
      </Content>
      {/* <ImageGalleryContainer>
        <ImageGallerySectionTitle>Photo Gallery</ImageGallerySectionTitle>
        <ImageGallerySectionP>See photos from the 2022 AgroExpo</ImageGallerySectionP>
      </ImageGalleryContainer> */}
      <AdditionalResources
        resources={[
          // {
          //   title: 'More about the AgroExpo',
          //   links: [
          //     <OutboundLink href='https://www.theagroexpo.com/images/2023/_2023_AgroExpo_ShowGuide_lowres.pdf'>
          //       Show Guide
          //     </OutboundLink>,
          //     <OutboundLink href='https://www.theagroexpo.com/images/2023/_2023_AgroExpo_ShowGuide_lowres.pdf'>
          //       Speakers
          //     </OutboundLink>,
          //   ],
          // },
          {
            title: 'More about Hutson, Inc.',
            links: [
              <Link to='/about/'>About us</Link>,
              <Link to='/locations/'>Our locations</Link>,
              <Link to='/contact/'>Contact us</Link>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 400px;
  text-align: center;

  @media (max-width: 899px) {
    .mobile-hidden {
      display: none;
    }
  }

  @media (min-width: 900px) {
    height: 500px;
  }
`

const Subtitle = styled(P)`
  color: #fff;
  font-size: 24px;

  @media (min-width: 900px) {
    font-size: 27px;
  }
`

const EventInfo = styled(P)`
  color: #fff;
`

const ButtonA = styled(OutboundLink)`
  display: inline-block;
  text-decoration: none;
`

const Grid = styled.div`
  @media (min-width: 800px) {
    ${clearfix}
  }
`

const VenueGrid = styled(Grid)`
  @media (min-width: 900px) {
    display: flex;
    align-items: center;
  }
`

const VenueColumn = styled.div`
  :first-child {
    margin-bottom: 24px;
  }

  @media (min-width: 900px) {
    ${column('1/2')}

    :first-child {
      margin-bottom: 0;
    }
  }
`

const OneHalfColumn = styled.div`
  margin-bottom: ${props => props.theme.size.l};

  @media (min-width: 800px) {
    ${column('1/2')}
  }
`

const HotelColumn = styled.div`
  margin-bottom: ${props => props.theme.size.l};

  @media (min-width: 800px) {
    ${column('1/2')}
  }

  @media (min-width: 1200px) {
    ${column('1/3')}
  }
`

const LogoColumn = styled.div`
  @media (min-width: 900px) {
    ${column('1/4')}
  }
`

const AboutColumn = styled.div`
  margin-bottom: ${props => props.theme.size.l};

  @media (min-width: 900px) {
    ${column('3/4')}
    margin-bottom: 0;
  }
`

const SessionContainer = styled(AspectRatioContainer)`
  height: auto;
  position: relative;
  width: 100%;
`

const SessionImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const SessionImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const SessionContent = styled.div`
  background-color: #eaebeb;
  padding: 24px;
`

// const ImageGalleryContainer = styled.div`
//   background-color: ${props => props.theme.color.n900};
// `

// const ImageGallerySectionTitle = styled(H2)`
//   color: #fff;
// `

// const ImageGallerySectionP = styled(P)`
//   color: #fff;
// `

const Schedules = styled(Grid)`
  > div {
    margin-bottom: 24px;
  }

  @media (min-width: 900px) {
    ${clearfix}

    > div {
      ${column('1/2')}
      margin-bottom: 0;
    }
  }
`

const ScheduleGroupHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  background-color: #377539;
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  width: 100%;

  h3 {
    color: #fff;
  }

  p {
    color: #fff;
    font-size: 21px;
    margin: 0;
  }
`

const ScheduleGroupContent = styled.div`
  background-color: #fff;
  padding: 24px;
`

const ScheduleTable = styled.table`
  border: none;
  border-collapse: collapse;

  tr {
    :not(:last-child) {
      td {
        padding-bottom: 16px;
      }
    }

    td {
      vertical-align: top;

      :last-child {
        padding-left: 24px;
      }
    }
  }

  p {
    margin: 0;
  }

  td:first-child {
    width: 160px;
  }

  @media (max-width: 479px) {
    tr {
      :not(:last-child) {
        td {
          padding-bottom: 0;

          :last-child {
            padding-bottom: 16px;
          }
        }
      }

      td {
        display: block;

        :last-child {
          padding-left: 0;
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query agroExpo2024Query {
    heroImage: file(relativePath: { eq: "events/agroexpo-2023/agroexpo-2023-hero.jpg" }) {
      ...FullWidthImage
    }
    partsImage: file(relativePath: { eq: "events/agroexpo-2023/parts.jpg" }) {
      ...SharpImage900
    }
    kondexImage: file(relativePath: { eq: "events/agroexpo-2023/kondex-concaves.jpg" }) {
      ...SharpImage900
    }
    agStormImage: file(relativePath: { eq: "events/agroexpo-2023/ag-storm-hydra-box.jpg" }) {
      ...SharpImage900
    }
    newEquipmentImage: file(relativePath: { eq: "events/agroexpo-2023/new-equipment.jpg" }) {
      ...SharpImage900
    }
    venueImage: file(
      relativePath: { eq: "events/agroexpo-2023/north-central-research-station.jpg" }
    ) {
      ...SharpImage900
    }
    logo: file(relativePath: { eq: "events/agroexpo-2023/agroexpo-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 240)
      }
    }
  }
`

export default AgroExpo2024Page
